import 'common/globalDefinitions';

import packageJson from '../../package.json';

////////////////////////
/////////////////////////////////////

///////////////////////////////////////////////////
////////////////
//////////

const toNumber = (str: string | undefined): number | undefined => {
  if (str === undefined) {
    return undefined;
  }

  const val = parseInt(str, 10);
  return val;
};

export const environment = {
  AwsKey: process.env.AWS_ACCESS_KEY_ID as string,
  AwsSecretKey: process.env.AWS_SECRET_ACCESS_KEY as string,
  url: process.env.LOCALHOST as string,
  npmVersion: packageJson.version,
  mode: process.env.NODE_ENV === 'development' ? 'Development' : 'Production',
  serverPort: toNumber(process.env.SERVER_PORT),
  clientPort: toNumber(process.env.SPA_PORT),
  branch: BRANCH,
  version: VERSION,
  app: APP,
  target: TARGET,
  infiniteMode: process.env.INFINITE_MODE === 'true',
};
